exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-contentful-page-tsx": () => import("./../../../src/templates/ContentfulPage.tsx" /* webpackChunkName: "component---src-templates-contentful-page-tsx" */),
  "component---src-templates-contentful-policy-page-tsx": () => import("./../../../src/templates/ContentfulPolicyPage.tsx" /* webpackChunkName: "component---src-templates-contentful-policy-page-tsx" */),
  "component---src-templates-contentful-web-view-page-tsx": () => import("./../../../src/templates/ContentfulWebViewPage.tsx" /* webpackChunkName: "component---src-templates-contentful-web-view-page-tsx" */),
  "component---src-templates-policy-page-jsx": () => import("./../../../src/templates/PolicyPage.jsx" /* webpackChunkName: "component---src-templates-policy-page-jsx" */),
  "component---src-templates-safety-center-page-jsx": () => import("./../../../src/templates/SafetyCenterPage.jsx" /* webpackChunkName: "component---src-templates-safety-center-page-jsx" */),
  "component---src-templates-safety-center-page-web-jsx": () => import("./../../../src/templates/SafetyCenterPageWeb.jsx" /* webpackChunkName: "component---src-templates-safety-center-page-web-jsx" */)
}

